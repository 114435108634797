import "./react.css";

function React() {
  return (
    <div className="ReactContainer">
      <div></div>
      <div></div>
      <div></div>
      <span></span>
    </div>
  );
}

export default React;
